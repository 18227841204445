const searchForm = {
  title: '', // 名称
  type: 1, // 查询类型（ 1：查询服务类型 2：查询机构）
  serverBindingId: '', // 服务模块绑定ID
};

// 绑定类型文案枚举
export const BindingTypeTextEnum = {
  1: '服务类型',
  2: '供应商',
};

const serviceTable = {
  dataInfo: [],
  columns: [
    {
      title: '服务类型名称',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '排序',
      key: 'sort',
      dataIndex: 'sort',
      width: '150px',
      scopedSlots: { customRender: 'sort' },
    },
    {
      title: '创建人',
      dataIndex: 'createUser',
      key: 'createUser',
    },
    {
      title: '创建时间',
      dataIndex: 'createDateTime',
      key: 'createDateTime',
    },
    {
      title: '更新人',
      dataIndex: 'updateUser',
      key: 'updateUser',
    },
    {
      title: '更新时间',
      dataIndex: 'updateDateTime',
      key: 'updateDateTime',
    },
    {
      title: '操作',
      key: 'action',
      width: '150px',
      scopedSlots: { customRender: 'action' },
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};

const supplierTable = {
  dataInfo: [],
  columns: [
    {
      title: '供应商名称',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '创建人',
      dataIndex: 'createUser',
      key: 'createUser',
    },
    {
      title: '创建时间',
      dataIndex: 'createDateTime',
      key: 'createDateTime',
    },
    {
      title: '更新人',
      dataIndex: 'updateUser',
      key: 'updateUser',
    },
    {
      title: '更新时间',
      dataIndex: 'updateDateTime',
      key: 'updateDateTime',
    },
    {
      title: '操作',
      key: 'action',
      width: '150px',
      scopedSlots: { customRender: 'action' },
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};

export default {
  searchForm,
  BindingTypeTextEnum,
  serviceTable,
  supplierTable
};
