<template>
  <div class="binding-aggregation-page-container">
    <ds-header :title="title"></ds-header>
    <div style="position: absolute; right: 20px; top: 20px;">
      <a-button @click="$router.back()" type="primary">
        返回
      </a-button>
    </div>
    <a-radio-group v-model="searchForm.type" @change="changeType">
      <a-radio-button :value="1">
        服务类型
      </a-radio-button>
      <a-radio-button :value="2">
        供应商
      </a-radio-button>
    </a-radio-group>
    <a-form layout="inline" style="margin-top: 20px;">
      <a-form-item :label="searchTitle">
        <a-input v-model.trim="searchForm.title" :placeholder="`请输入${searchTitle}`" class="width200"
          label-width="70px" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px" v-if="showAdd">
      <a-button type="primary" @click="addServiceTypeOrSupplier">
        <a-icon type="plus" />
        {{ `添加${BindingTypeTextEnum[searchForm.type]}` }}
      </a-button>
    </div>
    <!-- 服务绑定列表 -->
    <div style="padding-top: 15px" v-show="searchForm.type === 1">
      <a-table :rowKey="(record, index) => index" :columns="serviceTable.columns" :data-source="serviceTable.dataInfo"
        :pagination="serviceTable.pagination.total ? serviceTable.pagination : false" bordered @change="pageChange">
        <span slot="sort" slot-scope="text, record" class="action">
          <div v-if="$checkButtonPermissions($buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.bindingAggregationPage.serviceTypeSort)">
            <a-input-number id="inputNumber" :value="record.sort" @change="changeSort" :min="1" @blur="updateSort(record)" />
          </div>
          <div v-else>
            {{ record.sort }}
          </div>
        </span>
        <span slot="action" slot-scope="text, record" class="action" v-if="showDelete">
          <a-button class="btnClass" type="link" @click="remove(record)">
            删除
          </a-button>
        </span>
      </a-table>
    </div>
    <!-- 供应商绑定列表 -->
    <div style="padding-top: 15px" v-show="searchForm.type === 2">
      <a-table :rowKey="(record, index) => index" :columns="supplierTable.columns" :data-source="supplierTable.dataInfo"
        :pagination="supplierTable.pagination.total ? supplierTable.pagination : false" bordered @change="pageChange">
        <span slot="action" slot-scope="text, record" class="action" v-if="showDelete">
          <a-button class="btnClass" type="link" @click="remove(record)">
            删除
          </a-button>
        </span>
      </a-table>
    </div>
    <BindingServiceAndSupplier v-if="show" :isShow.sync="show" :type="searchForm.type"
      :serverBindingId="searchForm.serverBindingId" @refreshList="getList" />
  </div>
</template>

<script>
import BindingServiceAndSupplier from './components/BindingServiceAndSupplier.vue';
import help from './help';
import * as api from '@/api/entrepreneurClubManagement/serverManagement';
import pageRules from "@/utils/pagingConfig/pageUtils.js";
export default {
  components: {
    BindingServiceAndSupplier
  },
  computed: {
    title() {
      return `${this.$route.query.serverBindingName}绑定${this.BindingTypeTextEnum[this.searchForm.type]}`;
    },
    searchTitle() {
      return `${this.BindingTypeTextEnum[this.searchForm.type]}名称`
    },
    showAdd() {
      if (this.searchForm.type === 1) {
        return this.$checkButtonPermissions(this.$buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.bindingAggregationPage.addServiceType);
      } else {
        return this.$checkButtonPermissions(this.$buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.bindingAggregationPage.addSupplier)
      }
    },
    showDelete() {
      if (this.searchForm.type === 1) {
        return this.$checkButtonPermissions(this.$buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.bindingAggregationPage.deleteServiceType);
      } else {
        return this.$checkButtonPermissions(this.$buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.bindingAggregationPage.deleteSupplier)
      }
    }
  },
  data() {
    return {
      BindingTypeTextEnum: help.BindingTypeTextEnum,
      serviceTable: {
        ...help.serviceTable
      },
      supplierTable: {
        ...help.supplierTable
      },
      searchForm: {
        ...help.searchForm
      },
      show: false,
      tempSort: undefined
    };
  },
  methods: {
    /**网络请求 */
    // 获取已绑定服务类型或供应商列表
    async getList() {
      try {
        const pageNo = this.searchForm.type === 1 ? this.serviceTable.pagination.current : this.supplierTable.pagination.current;
        const pageSize = this.searchForm.type === 1 ? this.serviceTable.pagination.pageSize : this.supplierTable.pagination.pageSize;
        const res = await api.getAlreadyBindingServiceTypeOrSupplier({
          ...this.searchForm,
          pageNo,
          pageSize
        });
        if (res.code === this.$SUCCESS_CODE) {
          if (this.searchForm.type === 1) {
            this.serviceTable.dataInfo = res.data.data;
            this.serviceTable.pagination.total = res.data.total;
          } else {
            this.supplierTable.dataInfo = res.data.data;
            this.supplierTable.pagination.total = res.data.total;
          }
        }
      } catch (error) {

      }
    },
    // 更新服务类型排序
    async updateAlredayBindingServiceTypeSort(params) {
      try {
        const res = await api.updateAlredayBindingServiceTypeSort(params);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 删除绑定信息
    async deleteBindindInfo(params) {
      try {
        const res = await api.deleteBindindInfo(params);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          if (this.searchForm.type === 1) {
            this.serviceTable.pagination.current = pageRules.pageRules(
              this.serviceTable.dataInfo.length,
              1,
              this.serviceTable.pagination.current
            );
          } else {
            this.supplierTable.pagination.current = pageRules.pageRules(
              this.supplierTable.dataInfo.length,
              1,
              this.supplierTable.pagination.current
            );
          }

          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {

      }
    },
    /**数据处理 */
    // 根据查询类型重置页码
    changeTypeResetPageNo() {
      if (this.searchForm.type === 1) {
        this.serviceTable.pagination.current = 1;
      } else {
        this.supplierTable.pagination.current = 1;
      }
    },
    changeType() {
      this.changeTypeResetPageNo();
      this.searchForm.title = '';
      this.getList();
    },
    search() {
      this.changeTypeResetPageNo();
      this.getList();
    },
    resetSearchForm() {
      this.changeTypeResetPageNo();
      this.searchForm.title = '';
      this.getList();
    },
    addServiceTypeOrSupplier() {
      this.show = true
    },
    changeSort(e) {
      this.tempSort = e;
    },
    updateSort(record) {
      console.log(record);
      const { id } = record;
      if (this.tempSort > 999) {
        this.tempSort = '';
        this.$message.warning('排序最大999');
        return;
      }
      record.sort = this.tempSort;
      this.updateAlredayBindingServiceTypeSort({ id, sort: this.tempSort });
    },
    remove(record) {
      const self = this;
      this.$confirm({
        title: `确定要删除当前${self.BindingTypeTextEnum[self.searchForm.type]}吗？`,
        onOk() {
          self.deleteBindindInfo({ id: record.id, type: self.searchForm.type });
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    pageChange(option) {
      if (this.searchForm.type === 1) {
        this.serviceTable.pagination.current = option.current;
        this.serviceTable.pagination.pageSize = option.pageSize;
      } else {
        this.supplierTable.pagination.current = option.current;
        this.supplierTable.pagination.pageSize = option.pageSize;
      }
      this.getList();
    },
  },
  created() {
    if (this.$route.query.serverBindingId) {
      this.searchForm.serverBindingId = this.$route.query.serverBindingId;
    }
    if (!this.$checkButtonPermissions(this.$buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.bindingAggregationPage.deleteServiceType)) {
      this.serviceTable.columns.pop();
    }
    if (!this.$checkButtonPermissions(this.$buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.bindingAggregationPage.deleteSupplier)) {
      this.supplierTable.columns.pop();
    }
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.binding-aggregation-page-container {
  position: relative;

  .width200 {
    width: 200px;
  }

  .btnClass {
    padding: 0px;
    padding-right: 5px;
  }
}
</style>