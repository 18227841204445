<template>
  <div class="addMenu">
    <a-modal v-model="show" :title="title" :footer="null" :maskClosable="false" @cancel="close">
      <div>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="服务类型关联" prop="bindingTypeList" v-if="type === 1">
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.bindingTypeList"
              placeholder="请选择" :filter-option="filterOption" show-search mode="multiple">
              <a-select-option v-for="(item, index) in allowBindingList" :key="index" :value="item.id">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="供应商关联" prop="bindingSupplierList" v-else>
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.bindingSupplierList"
              placeholder="请选择" :filter-option="filterOption" show-search mode="multiple">
              <a-select-option v-for="(item, index) in allowBindingList" :key="index" :value="item.id">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="text-align: center">
        <a-button @click="close">
          取消
        </a-button>
        <a-button style="margin-left: 20px" type="primary" @click="save">
          保存
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import help from '../help';
import * as api from '@/api/entrepreneurClubManagement/serverManagement';
export default {
  props: {
    type: {
      type: Number,
      default: () => 1
    },
    isShow: {
      type: Boolean,
      default: () => false
    },
    serverBindingId: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    title() {
      return `绑定${help.BindingTypeTextEnum[this.type]}`
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      show: false,
      form: {
        bindingTypeList: [], // 服务类型绑定集合
        bindingSupplierList: [], // 机构绑定集合
      },
      rules: {
        bindingTypeList: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        bindingSupplierList: [
          { required: true, message: "请选择", trigger: "blur" },
        ]
      },
      allowBindingList: [],
    };
  },
  methods: {
    /**网络请求 */
    // 查询可绑定服务或供应商列表
    async findAllowBindingServiceTypeOrSupplierList() {
      try {
        const res = await api.findAllowBindingServiceTypeOrSupplierList({
          serverBindingId: this.serverBindingId,
          type: this.type
        });
        if (res.code === this.$SUCCESS_CODE) {
          this.allowBindingList = [...res.data];
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 添加服务板块
    async bindingServiceTypeOrSupplier() {
      try {
        const res = await api.bindingServiceTypeOrSupplier({
          ...this.form,
          serverBindingId: this.serverBindingId
        });
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.$emit('refreshList');
          this.close();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
        this.$message.error('操作失败');
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    close() {
      this.$emit('update:isShow', false);
    },
    save() {
      console.log(this.form);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.bindingServiceTypeOrSupplier();
        }
      });
    },
  },
  created() {
    this.show = this.isShow;
    this.findAllowBindingServiceTypeOrSupplierList();
  },
};
</script>

<style lang="scss" scoped></style>